// @import './style/lib/antd';
@import 'antd/dist/antd.less';
@import 'antd/dist/antd.dark.less';
@import 'tailwindcss/base';
// @import "./custom-base-styles.css";
@import 'tailwindcss/components';
// @import "./custom-components.css";
@import 'tailwindcss/utilities';
// @import "./custom-utilities.css";

.bgbg {
  background: rgba(0, 0, 0, 0.7);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

input[type='number'] {
  appearance: textfield;
}

@text-color: #FFFFFF;@primary-color: #01D1FF;@border-color-base: rgba(75,85,99,var(--tw-border-opacity));@border-radius-base: 16px;